<template>
  <div ref="googleMapElement" class="size-full" />
</template>

<script setup lang="ts">
import useGoogleMap, { type CreateMapOptions } from '@/composables/useGoogleMap'

const props = defineProps<{ options: Omit<CreateMapOptions, 'htmlElement'> }>()
const googleMapElement = ref<HTMLElement | null>(null)
const googleMap = await useGoogleMap()

onMounted(async () => {
  watch(
    () => props.options,
    () => {
      if (!googleMapElement.value) {
        return
      }

      googleMap?.createMap({
        htmlElement: googleMapElement.value,
        ...props.options,
      })
    },
    { immediate: true },
  )
})
</script>

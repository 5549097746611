import type { AssetStoryblok } from '@/types/storyblok'
import type { Cluster } from '@googlemaps/markerclusterer'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

// global promise to ensure the Google Maps script is only loaded once
let googleMapsPromise: Promise<void> | undefined

export default async function useGoogleMap() {
  const { googleMapsApiKey } = await useConfigStore().asyncConfig()

  if (import.meta.server || !googleMapsApiKey?.value) {
    return
  }

  let map: google.maps.Map | undefined

  await loadGoogleMaps(googleMapsApiKey.value)

  const { Map } = (await google.maps.importLibrary(
    'maps',
  )) as google.maps.MapsLibrary
  const { AdvancedMarkerElement } = (await google.maps.importLibrary(
    'marker',
  )) as google.maps.MarkerLibrary

  async function loadGoogleMaps(apiKey: string): Promise<void> {
    if (!googleMapsPromise) {
      googleMapsPromise = new Promise((resolve) => {
        window.initGoogleMap = () => {
          resolve()
        }

        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initGoogleMap&loading=async`
        script.async = true
        script.defer = true

        document.head.appendChild(script)
      })
    }
    return googleMapsPromise
  }

  function createMap({
    googleMapOptions,
    htmlElement,
    position,
    zoom,
    markers,
    setBoundsToMarkers,
    setBoundsToCountry,
    hasSafeArea,
  }: CreateMapOptions): void {
    map = new Map(htmlElement, {
      center: position,
      zoom,
      mapId: 'e8c19db7b4daefbe',
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      ...googleMapOptions,
    })

    if (markers?.length) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds()
      const mapMarkers: google.maps.marker.AdvancedMarkerElement[] = []

      markers.forEach((marker) => {
        mapMarkers.push(
          addMarker({
            position: marker.position,
            communityDetails: marker.communityDetails,
            link: marker.link,
          }),
        )

        if (setBoundsToMarkers && !setBoundsToCountry) {
          bounds.extend(marker.position)
        }
      })

      if (markers.length > 1) {
        const clusterer = new MarkerClusterer({
          map,
          markers: mapMarkers,
          algorithmOptions: {
            maxZoom: 150,
          },
          renderer: {
            render: ({ count, position }: Cluster) =>
              addMarker({
                position,
                cluster: {
                  label: count,
                },
              }),
          },
        })

        if (hasSafeArea) {
          clusterer.addListener('click', () => {
            adjustMapBoundsForSafeArea(map)
          })
        }
      }

      if (setBoundsToCountry) {
        map.setCenter({ lat: -25.2744, lng: 133.7751 }) // Center of Australia

        // Define the bounds using the northernmost and southernmost points of Australia
        const australiaBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(-43.634, 112.0), // Southwest corner (Tasmania, with some padding on the west)
          new google.maps.LatLng(-10.687, 154.0), // Northeast corner (Cape York, with some padding on the east)
        )

        // Fit the map to these bounds
        map.fitBounds(australiaBounds)
      }

      if (setBoundsToMarkers) {
        map.fitBounds(bounds)
      }

      if (hasSafeArea) {
        adjustMapBoundsForSafeArea(map)
      }
    }
  }

  function addMarker({
    position,
    communityDetails,
    link,
    cluster,
  }: {
    position: google.maps.LatLngLiteral | google.maps.LatLng
    communityDetails?: CommunityDetails
    link?: string
    cluster?: {
      label: number
    }
  }): google.maps.marker.AdvancedMarkerElement {
    const options: google.maps.marker.AdvancedMarkerElementOptions = {
      position,
      map,
    }

    if (communityDetails) {
      options.content = createCommunityMarker(communityDetails)
    }

    if (cluster) {
      options.content = createClusterMarker(cluster.label)
    }

    const marker = new AdvancedMarkerElement(options)

    if (link) {
      marker.addListener('click', () => {
        window.location.href = `/${link}`
      })
    }

    return marker
  }

  function createCommunityMarker(
    communityDetails: CommunityDetails,
  ): HTMLElement {
    const parser = new DOMParser()
    const communityDetailsHtml = `
      <div class="relative">
        <svg xmlns="http://www.w3.org/2000/svg" width="108" height="127" fill="none">
          <path fill="${communityDetails.color}" d="M72.18 104.863C93.06 97.4 108 77.445 108 54c0-29.823-24.177-54-54-54S0 24.177 0 54c0 23.537 15.06 43.558 36.068 50.951 8.273 2.912 14.032 8.3 16.69 17.549.669 2.323.742 4 1.409 4 .666 0 .741-1.674 1.412-4 2.672-9.277 8.455-14.725 16.6-17.637Z"/>
          <path stroke="#000" stroke-opacity=".3" d="M107.5 54c0 23.227-14.802 42.998-35.489 50.393-8.285 2.961-14.192 8.525-16.913 17.969a30.841 30.841 0 0 0-.416 1.632l-.085.372c-.076.334-.143.625-.21.879a3.653 3.653 0 0 1-.22.659 3.648 3.648 0 0 1-.22-.659 29.538 29.538 0 0 1-.207-.876l-.086-.376a30.7 30.7 0 0 0-.415-1.631c-2.707-9.418-8.593-14.922-17.005-17.882C15.42 97.154.5 77.319.5 54 .5 24.453 24.453.5 54 .5s53.5 23.953 53.5 53.5Zm-53.409 72.024.004-.003a.023.023 0 0 1-.004.003Zm.148-.003a.137.137 0 0 0 .004.003l-.004-.003Z"/>
        </svg>
        <img
          src="${communityDetails.logo?.filename}"
          alt="${communityDetails.logo?.alt}"
          class="absolute top-0 translate-y-4 size-20 left-1/2 transform -translate-x-1/2"
        />
      </div>`

    return parser.parseFromString(communityDetailsHtml, 'text/html')
      .documentElement
  }

  function createClusterMarker(total: number | undefined): HTMLElement {
    const parser = new DOMParser()
    const clusterMarkerHtml = `<div class="size-[50px] flex items-center justify-center text-center font-medium text-white text-[18px] bg-primary-500/90 rounded-full">
      ${total}
    </div>`

    return parser.parseFromString(clusterMarkerHtml, 'text/html')
      .documentElement
  }

  function adjustMapBoundsForSafeArea(map: google.maps.Map | undefined): void {
    if (!map) {
      return
    }

    nextTick(() => {
      // Get the current bounds of the map
      const bounds = map.getBounds()
      if (!bounds) return

      // Get the map projection and calculate the necessary adjustments
      const projection = map.getProjection()
      if (!projection) return

      const sw = bounds.getSouthWest()
      const ne = bounds.getNorthEast()
      if (!sw || !ne) return

      // Convert latitude to y-coordinate (map units)
      const swLatLng = projection.fromLatLngToPoint(sw)
      const neLatLng = projection.fromLatLngToPoint(ne)
      if (!swLatLng || !neLatLng) return

      // Ensure the map's div is available
      const mapDiv = map.getDiv()
      if (!mapDiv) return

      // Calculate the height in pixels of the map projection
      const mapHeightPixels = mapDiv.clientHeight
      // Check if the mapHeightPixels is non-zero to avoid division errors
      if (mapHeightPixels <= 0) return

      // Define a padding value (in pixels) for the safe area at the bottom
      const padding = window.innerHeight

      // Adjust the south-east and north-east points according to the safe area
      const adjustedSouthY =
        swLatLng.y + (padding / mapHeightPixels) * (neLatLng.y - swLatLng.y)
      const adjustedNorthY =
        neLatLng.y - (padding / mapHeightPixels) * (neLatLng.y - swLatLng.y)

      const newSouthWest = projection.fromPointToLatLng(
        new google.maps.Point(swLatLng.x, adjustedSouthY),
      )
      const newNorthEast = projection.fromPointToLatLng(
        new google.maps.Point(neLatLng.x, adjustedNorthY),
      )
      const newBounds = new google.maps.LatLngBounds(newSouthWest, newNorthEast)

      map.fitBounds(newBounds)
    })
  }

  return {
    createMap,
  }
}

export interface CreateMapOptions {
  htmlElement: HTMLElement
  position: google.maps.LatLngLiteral
  zoom?: number
  googleMapOptions?: google.maps.MapOptions
  markers?: CreateMapMarkerOptions[]
  setBoundsToMarkers?: boolean
  setBoundsToCountry?: boolean
  hasSafeArea?: boolean
}

export interface CreateMapMarkerOptions {
  position: google.maps.LatLngLiteral
  communityDetails: CommunityDetails
  link?: string
}

export interface CommunityDetails {
  color?: `#${string}`
  logo?: AssetStoryblok
}

declare global {
  interface Window {
    initGoogleMap: () => void
  }
}
